<template>
  <div>
    <v-img
      :src="require('@/assets/images/letterhead-associate.png')"
      class="mx-auto mb-2"
    >
      <template v-slot:placeholder>
        <v-sheet>
          <v-skeleton-loader type="image" />
        </v-sheet>
      </template>
    </v-img>
  </div>
</template>

<script>
import { VSkeletonLoader } from 'vuetify/lib'

export default {
  components: {
    VSkeletonLoader,
  },
  setup() {
    return {}
  },
}
</script>

<style scoped>
  p {
    font-size: 9px !important;
  }
</style>
